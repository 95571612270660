import MyApp from '@speedwaymotors/clutch/Layout/Next/_app'


function MyAppGateWay({ Component, pageProps }) {
    if (pageProps.calckey) {
        return <Component pageProps={pageProps} />
    }

    return <MyApp Component={Component} pageProps={pageProps} />
}

export default MyAppGateWay;